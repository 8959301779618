/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 contact:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es/array';
// import 'core-js/es/date';
// import 'core-js/es/function';
// import 'core-js/es/map';
// import 'core-js/es/math';
// import 'core-js/es/number';
// import 'core-js/es/object';
// import 'core-js/es/reflect';
// import 'core-js/es/regexp';
// import 'core-js/es/set';
// import 'core-js/es/string';
// import 'core-js/es/symbol';
// import 'core-js/es/weak-map';
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

/**
 * We need to initialize window.process with { env: { DEBUG: undefined } } because it's likely
 * that some libs assume they inherit this value as they would in node.js, however window.process.env
 * is not defined in the browser, and that causes the app to fail :(
 */
(window as any).process = { env: { DEBUG: undefined } };

import 'date-input-polyfill'; // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

import 'url-search-params-polyfill';
/***************************************************************************************************
 * APPLICATION IMPORTS
 */
// import cssVars from 'css-vars-ponyfill';
// cssVars({});
